<template>
  <section id="staff_list">
    <div class="breadcrumbs w1400">
      <p>
        <span>{{ staffGroupTitle }}</span>
        >
        <router-link :to="$route.params.subject">{{ staffGroupName }}</router-link>
      </p>
    </div>
    <div class="staff_box w1400">
      <div class="fliter_outer">
        <MenuClassificationFilter
          :Classification="Classification"
          :ClassificationTcm="ClassificationTcm"
          :ClassificationLabs="ClassificationLabs"/>
      </div>
      <div class="itembox itembox_divid" v-if="$route.path === '/Staff/Ivf/1'">
        <div class="top">
          <template v-for="(item, key) in showStaff">
            <div class="staff_item"
              :key="key"
              v-if="key === 0 || key === 1"
              @click="clickItem(item.memId, item.ifOpenLightbox)">
              <figure class="pic" :style="{backgroundImage: `url(${item.cover})`}"></figure>
              <h6 class="txt-medium">{{item.name}} {{item.jobTitle}}</h6>
            </div>
          </template>
        </div>
        <div class="bottom">
          <template v-for="(item, key) in showStaff">
            <div class="staff_item"
              :key="key"
              v-if="key === 2 || key === 3 || key === 4"
              @click="clickItem(item.memId, item.ifOpenLightbox)">
              <figure class="pic" :style="{backgroundImage: `url(${item.cover})`}"></figure>
              <h6 class="txt-medium">{{item.name}} {{item.jobTitle}}</h6>
            </div>
          </template>
        </div>
      </div>
      <div class="itembox" v-else>
        <div class="staff_item"
          v-for="(item, key) in showStaff"
          :key="key"
          @click="clickItem(item.memId, item.ifOpenLightbox)">
          <figure class="pic" :style="{backgroundImage: `url(${item.cover})`}"></figure>
          <h6 class="txt-medium">{{item.name}} {{item.jobTitle}}</h6>
        </div>
      </div>
    </div>

    <div id="staff_lightbox"
      v-show="lightboxOpen">
      <div class="cover" @click="clickCloseLightbox()"></div>
      <div class="box">
        <div class="light_box_close" @click="clickCloseLightbox()">
          <img src="@/assets/img/x.svg" alt="">
        </div>
        <div class="pic_outer">
          <div class="pic"
            :style="{backgroundImage: `url(${lightboxShow.avatar})`}">
          </div>
        </div>
        <div class="text">
          <h5 class="h5-25 txt-bold">{{`${lightboxShow.name} ${lightboxShow.jobTitle}`}}</h5>
          <div class="content">
            <p v-html="lightboxShow.content">
            </p>
          </div>
          <!-- <div class="education">
            <p class="small"
              v-for="(item, key) in lightboxShow.education"
              :key="key">
              {{item}}
            </p>
          </div>
          <div class="experience" v-if="lightboxShow.nowin">
            <p class="small txt-bold">現任</p>
            <p class="small txt-dark_gray"
              v-for="(item, key) in lightboxShow.nowin"
              :key="key">
              {{item}}
            </p>
          </div>
          <div class="experience" v-if="lightboxShow.experience">
            <p class="small txt-bold">經歷</p>
            <p class="small txt-dark_gray"
              v-for="(item, key) in lightboxShow.experience"
              :key="key">
              {{item}}
            </p>
          </div>
          <div class="expertise" v-if="lightboxShow.expertise">
            <p class="small txt-bold">主治專長</p>
            <p class="small txt-dark_gray"
              v-for="(item, key) in lightboxShow.expertise"
              :key="key">
              {{item}}
            </p>
          </div> -->
          <div class="outer_link txt-right"
          v-show="this.$route.params.subject === 'p1' ||
          this.$route.params.subject === 't1'  ||
          this.$route.params.subject === 'p3'">
            <button
              type="button"
              class="btn a"
              :class="[getLanguage === 'en-US' ? 'btnEn' : '']"
              @click="removeBodyLock(lightboxShow.url)"
            >{{$t('bookingNow')}}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MenuClassificationFilter from '@/components/MenuClassificationFilter.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'StaffList',
  components: {
    MenuClassificationFilter,
  },
  props: ['showStaff', 'Classification', 'ClassificationTcm', 'ClassificationLabs'],
  data() {
    return {
      lightboxShow: Object,
      lightboxOpen: false,
      staffGroupTitle: '',
      staffGroupName: '',
    };
  },
  computed: {
    groupDataCollection() {
      return [...this.Classification, ...this.ClassificationTcm, ...this.ClassificationLabs];
    },
    ...mapGetters([
      'getLanguage',
    ]),
  },
  methods: {
    clickItem(id, ifOpenLightbox) {
      let nowMem;
      this.showStaff.forEach((elem) => {
        if (elem.memId === id) {
          nowMem = elem;
        }
      });

      // 更換lightbox內的資料
      this.lightboxShow = nowMem;

      if (ifOpenLightbox !== false && this.lightboxShow.content !== null) {
        // 開啟lightbox
        this.lightboxOpen = true;
        const el = document.getElementsByTagName('body')[0];
        el.classList.add('lock');
      }
    },
    clickCloseLightbox() {
      this.lightboxOpen = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    removeBodyLock(url) {
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
      if (url === null) {
        this.$router.push('/registration');
      } else {
        window.location.href = url;
      }
    },
    groupChangeHandler(path) {
      for (let i = 0; i < this.groupDataCollection.length; i += 1) {
        const item = this.groupDataCollection[i];
        if (item.path === path) {
          this.staffGroupName = item.linkName;
        }
      }
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.groupChangeHandler(to.path);
    },
  },
  created() {
    if (this.$route.params.tagSlug === 'procreation') {
      this.staffGroupTitle = this.$t('nuwaIvf');
    } else if (this.$route.params.tagSlug === 'traditional') {
      this.staffGroupTitle = this.$t('nuwaTcm');
    } else {
      this.staffGroupTitle = this.$t('nuwaLabs');
    }
  },
  updated() {
    if (this.$route.params.tagSlug === 'procreation') {
      this.staffGroupTitle = this.$t('nuwaIvf');
    } else if (this.$route.params.tagSlug === 'traditional') {
      this.staffGroupTitle = this.$t('nuwaTcm');
    } else {
      this.staffGroupTitle = this.$t('nuwaLabs');
    }

    const initPath = this.$route.path;
    this.groupChangeHandler(initPath);
  },
};
</script>
