var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu_classification_filter_outer"},[_c('div',{staticClass:"menu_classification_filter_toggle",class:{active: _vm.openMenu},on:{"click":function($event){_vm.openMenu = !_vm.openMenu}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"19.72","height":"10.86","viewBox":"0 0 19.72 10.86"}},[_c('g',{attrs:{"transform":"translate(1.414 9.86) rotate(-90)"}},[_c('path',{attrs:{"d":"M-445.412,268.363l-8.446-8.446,8.446-8.446","transform":"translate(453.858 -251.471)","fill":"none","stroke":"#af566d","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2"}})])])]),_c('ul',{staticClass:"menu_classification_filter",class:{active: _vm.openMenu}},[(_vm.isStaff)?_c('span',[_vm._v(_vm._s(_vm.$t('nuwaIvf')))]):_vm._e(),_vm._l((_vm.Classification),function(item,key){return _c('li',{key:key,class:{
        active :
        _vm.$route.path.indexOf(encodeURI(item.path)) >= 0 ||
        _vm.$route.path.indexOf(item.path) >= 0 ||
        (_vm.$route.name === 'News' && _vm.$route.params.tagSlug === item.path.split('/')[2])
      }},[_c('router-link',{attrs:{"to":item.path}},[_c('p',{staticClass:"small"},[_vm._v(" "+_vm._s(item.linkName)+" ")])])],1)}),(_vm.isStaff)?_c('br'):_vm._e(),(_vm.isStaff)?_c('span',[_vm._v(_vm._s(_vm.$t('nuwaTcm')))]):_vm._e(),_vm._l((_vm.ClassificationTcm),function(item,key){return _c('li',{key:("tcm_" + key),class:{
        active :
        _vm.$route.path.indexOf(encodeURI(item.path)) >= 0 ||
        _vm.$route.path.indexOf(item.path) >= 0
      }},[_c('router-link',{attrs:{"to":item.path}},[_c('p',{staticClass:"small"},[_vm._v(" "+_vm._s(item.linkName)+" ")])])],1)}),(_vm.isStaff)?_c('br'):_vm._e(),(_vm.isStaff)?_c('span',[_vm._v(_vm._s(_vm.$t('nuwaLabs')))]):_vm._e(),_vm._l((_vm.ClassificationLabs),function(item,key){return _c('li',{key:("labs_" + key),class:{
        active :
        _vm.$route.path.indexOf(encodeURI(item.path)) >= 0 ||
        _vm.$route.path.indexOf(item.path) >= 0
      }},[_c('router-link',{attrs:{"to":item.path}},[_c('p',{staticClass:"small"},[_vm._v(" "+_vm._s(item.linkName)+" ")])])],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }