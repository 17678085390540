<template>
  <div class="menu_classification_filter_outer">
    <div class="menu_classification_filter_toggle"
      :class="{active: openMenu}"
      @click="openMenu = !openMenu">
      <svg xmlns="http://www.w3.org/2000/svg" width="19.72" height="10.86" viewBox="0 0 19.72 10.86">
        <g transform="translate(1.414 9.86) rotate(-90)">
          <path d="M-445.412,268.363l-8.446-8.446,8.446-8.446" transform="translate(453.858 -251.471)" fill="none" stroke="#af566d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </g>
      </svg>
    </div>
    <ul class="menu_classification_filter"
      :class="{active: openMenu}">
      <span v-if="isStaff">{{$t('nuwaIvf')}}</span>
      <li
        v-for="(item, key) in Classification"
        :key="key"
        :class="{
          active :
          $route.path.indexOf(encodeURI(item.path)) >= 0 ||
          $route.path.indexOf(item.path) >= 0 ||
          ($route.name === 'News' && $route.params.tagSlug === item.path.split('/')[2])
        }">
        <router-link
          :to="item.path">
          <p class="small">
            {{item.linkName}}
          </p>
        </router-link>
      </li>
      <br v-if="isStaff">
      <span v-if="isStaff">{{$t('nuwaTcm')}}</span>
      <li
        v-for="(item, key) in ClassificationTcm"
        :key="`tcm_${key}`"
        :class="{
          active :
          $route.path.indexOf(encodeURI(item.path)) >= 0 ||
          $route.path.indexOf(item.path) >= 0
        }">
        <router-link
          :to="item.path">
          <p class="small">
            {{item.linkName}}
          </p>
        </router-link>
      </li>
      <br v-if="isStaff">
      <span v-if="isStaff">{{$t('nuwaLabs')}}</span>
      <li
        v-for="(item, key) in ClassificationLabs"
        :key="`labs_${key}`"
        :class="{
          active :
          $route.path.indexOf(encodeURI(item.path)) >= 0 ||
          $route.path.indexOf(item.path) >= 0
        }">
        <router-link
          :to="item.path">
          <p class="small">
            {{item.linkName}}
          </p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MenuClassificationFilter',
  props: ['Classification', 'ClassificationTcm', 'ClassificationLabs', 'NewsByTagData', 'tagSlug'],
  data() {
    return {
      openMenu: false,
      isStaff: false,
    };
  },
  created() {
    if (this.$route.name === 'Staff') {
      this.isStaff = true;
    }
    // console.log(this.$route.params);
    // console.log(this.$route.query);
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.openMenu = false;
    },
  },
};
</script>
